/* src/components/Header.css */

.link {
    color: white;
    cursor: pointer;
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth transition */
}

.link:hover {
  color: #E21F46; /* Change color to red on hover */
}

.logo{
  display: flex;
  max-height: 100px;
  position: absolute;
  
  line-height: 0px;
  transition: max-height 0.2s ease;
  width: auto;
  height: auto;
  left:140px;
  /* margin-right: 10px;  */
  z-index: 999;
  
  min-height: 100px;
  max-width: 394px;
}

.navbar-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out items */
  color:#E21F46;
  text-transform: uppercase;
  font-weight: bold;
}
.nav-menu-position {
  
  min-height: 100px;
  margin:0;
  /* z-index: 99999999999999999999; */
} 

.navbar-toggler {
  margin-left: 300px;
  align-items: center;
  align-content: center;
}



.navbar {
    flex-grow: 1;
    
}

.navbar-menu-items .nav-link,
.navbar-menu-items .nav-item, 
.navbar-menu-items .nav-dropdown
.navbar-menu-items .dropdown-item {
  margin-left: 30px;
  color:#E21F46;
  text-shadow: #E21F46;
  font-size: 20px;
}

  .top-div {
    background-color: #003366;
    /* height:34px; */
    height:44px;
    color: white;
    padding: 5px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    margin:auto;
    align-items: center;
    padding: 10px 0px;
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
    position: fixed; /* Keep the bar fixed on scroll */
    top: 0; /* Position it at the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
    z-index: 1000; /* Ensure it stays above other content */
    background-color: #003366;
    height: inherit;
}

  .left{
    display: flex;
    flex-direction: column;
  }

  .right{
    display: flex;
  }

  .top-address {
    margin-left:25px;
    text-align:left;
    position:relative;
    top:-4px;
  }

  .phone{
    display: flex;
  }
  

  .nav-top{
    display: flex;
    height: 100%;
    position: relative;
    text-transform: uppercase;
     /* align-items: center; */
    justify-content: center; 
    padding: 0px 40px;
    height: 100px;
    flex: 0 0 100%;
  }

  

  @media (max-width: 768px) { /* Mobile devices (you can adjust the max-width value as needed) */
    .logo {
        max-height: 100px; /* Decrease the height for mobile view */
        width: calc(100px * 524 / 142); /* Calculate width based on aspect ratio (524:142) */
        left:0px;
        top:3.3px;
        /* top:-46px; */
        min-height: auto;
        height: 93px;
    }
    .top-address {
      width:160px;
      margin-left: 18px;
    }

    .phone{
      display: flex; 
      position: absolute; 
      margin-top: -27px;
    }

    .nav-top {
      height: 280px;
    }

    .top-div {
      height:84px;
    }

    .nav-menu-position {
      height: auto;
      /* top:12px; */
      
      margin-left: auto;
    }

    .navbar-menu-items .nav-link,
    .navbar-menu-items .nav-item, 
    .navbar-menu-items .nav-dropdown
    .navbar-menu-items .dropdown-item {
      margin-left: 0px;
      margin-top:4px;
      font-size:15px;
    }

}