.footer {
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: rgb(8, 9, 126);
    width: 100%;
    position: relative;
    box-sizing: border-box;
    flex-basis: 100%;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    
}

.footer .location {
    flex: 1; /* Allow the location section to take up remaining space */
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    align-content: center;
    margin: auto;
    max-width: 1200px;
    align-items: center;
}

.footer .text {
    flex-basis: 60%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer .text .h4 {
    margin: 0px;
    letter-spacing: 4px;
    line-height: 1.6em;
    text-align: left;
}

.footer .text .h3 {
    letter-spacing: 3px;
    font-size: 1.8em;
    text-align: left;
}

.footer .information {
    flex-basis: 50%;
    box-sizing: border-box;
    text-align: left;
    font-size:16px;
    line-height: 1.6rem;
}

.footer .office_hours {
    flex-basis: 45%;
    box-sizing: border-box;
    font-size: 15px;
    letter-spacing: 0;
    text-transform: none;
}

.footer .office_hours .row {
    display: flex;
    justify-content: space-between;
    line-height: 1.6rem;
}

.footer .map {
    display: flex;
    flex-basis: 80%;
    height: 450px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: right;
    margin: 50px auto
    /* margin: 50px 50px 50px 50px; */
}

.footer .footer-strip {
    height: 100px;
    background-color: rgb(0, 51, 102);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.center-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    margin-top:40px;
    margin-bottom: 50px;
    /* height: 100vh; */
  }
  
  .terms-content {
    max-width: 1320px; /* Limit the width of the content */
    padding: 20px; /* Add some padding */
    /* background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    text-align: justify;
  }

  a.link1 {
    font-weight: bold;
    color: black; /* initial color */
    text-decoration: none; /* Remove underline */
  }
  
  a.link1:hover {
    color: #E21F46; /* Hover color */
    text-decoration: underline; /* Remove underline */
  }

@media (max-width: 768px) { /* Mobile devices (you can adjust the max-width value as needed) */
    
    /* .footer .text {
       flex-direction: column;
       flex-basis: 90%;
       margin-left: 15px;
    } */
    .footer .location {
        flex-direction: column; /* Stack text and map vertically */
        align-items: center; /* Center align the items */
        margin: 50px auto;
    }
    .footer .text {
        flex-direction: column; /* Stack text vertically */
        flex-basis: 100%; /* Full width */
        margin-left: 15px; /* Reset margin */
     }
     .footer .text .h3 {
        margin:0;
        line-height: 1.6em;
        /* margin-bottom: -30px; */
    }
    .footer .text .h4 {
        
        letter-spacing: 4px;
        line-height: 0;
        text-align: left;
    }

    .footer .information {
        flex-basis: 0%;
    }

    .footer .office_hours {
    flex-basis: 0%;
    box-sizing: border-box;
    font-size: 15px;
    display: flex;
    flex-direction: column; /* Stack rows vertically */
    }

    .footer .office_hours .row {
        display: flex;
        justify-content: start; /* Align items to the start */
        line-height: 1.6rem;
        
    }
    
    .footer .office_hours .day {
        flex: 1; /* Allow day to take available space */
        justify-content: left; /* Align items to the start */
        display: flex;
    }
    
    .footer .office_hours .time {
        display: flex;
        flex: 1; /* Allow time to take available space */
        justify-content: left; /* Align items to the start */
    }
 
     .footer .map {
         flex-basis: 00%; /* Full width for map */
         height: 300px; /* Adjust height if needed */
         width: 130%;
         margin: -120px auto;
         margin-left: 15px;
         justify-content: space-between;
         margin-bottom: 0px;
     }
}