/* InsuranceSection.css */
.insurance-section {
    padding: 40px 0;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .insurance-section .container {
    max-width: auto;
    margin: 0 auto;
  }
  
  .insurance-section h2 {
    font-size: 40px;
    font-weight: bold;
    color: #E21F46;
    margin-bottom: 60px;
    transition: transform 0.3s ease-in-out;
  }

  .insurance-section h2:hover {
    transform: scale(1.1);
  }
  
  .logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  
  .insurance-logo {
    max-width: 250px;
    height: auto;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    margin-bottom:20px;
  }

  .insurance-logo:hover {
    transform: scale(1.1);
  }
  
  .insurance-section h2:hover {
    transform: scale(1.1);
  }



  @media (max-width: 768px) {
    .logos {
      flex-direction: column;
    }
    .insurance-section h2 {
        margin-left: 20px;
        margin-right: 20px;
    }
}