/* src/pages/Refills.css */

.container {
    max-width: 2800px;
    margin: 0; /* Center the container */
    padding: 0; /* Add padding */
    display: flex;
    flex-direction:column;
    
}

.img-fluid {
    /*
    border-radius: 8px; /* Rounded corners for the image 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
}

/* .h1 {
    font-size: 2.5rem; 
    margin-bottom: 20px; 
    color: #004080;
} */

.alert {
    margin-bottom: 20px; /* Space below the alert */
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 800px;
    margin: 40px auto;
  }

  .form-group {
    margin-bottom: 20px; /* Space between form groups */
    
  }
  
  .form-group label {
    font-weight: bold;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .button-container button {
    margin-bottom: 10px; /* Space between buttons */
  }


.image-wrapper {
    position: relative; /* Ensure relative positioning for absolute child elements */
}

.image-wrapper h1 {
    position: absolute; /* Position the heading absolutely within the wrapper */
    top: 20px; /* Adjust to move it higher or lower */
    bottom:40px;
    /* left: 50%; */
    transform: translateX(-50%); /* Adjust for perfect centering */
    color: white; /* Text color for visibility */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Optional: adds a shadow for better readability */

    align-content: end;
    font-size: 60px;
    left:17%;
}


 @media (max-width: 768px) { /* Mobile devices (you can adjust the max-width value as needed) */
    .img-fluid {
        height: 250px;
    }
    .card {
        max-width: 400px;
    }

    .image-wrapper h1 {
        font-size: 35px;
        font-weight: bold;
        left:35%;
    }
    
 }