/* src/css/Home.css */
.container {
    max-width: 2800px;
    margin:0px !important;
}
.carousel-inner {
    height: 800px; /* Set a fixed height for the entire carousel */
  }
  
  .carousel-item {
    height: 100%; /* Ensure each item fills the carousel */
    width:100%;
  }
  
  .carousel-item img {
    height: 100%; /* Ensure the image fills the item */
    object-fit: cover; /* Cover the area without stretching */
    width: 100%; /* Ensure full width */
  }
  
  .carousel .carousel-caption {
    position: absolute;
    left:auto;
    bottom:auto;
    top: 12vh; /* Adjust as needed */
    right: 20vh; /* Position from the right */
    color: white;
    text-align: left; /* Align text to the right */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px;
    border-radius: 5px;
    width: auto; /* Set width to auto to fit text */
    white-space: wrap; /* Prevent text wrapping */
    max-width: 540px;
  }

  .carousel-caption h2 {
    font-size: 4.5rem; /* Adjust size as needed */
    font-weight: bold; /* Make it bold */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Optional: Add shadow for emphasis */
    
  }
  
  .carousel-caption p {
    font-size: 1.25rem; /* Adjust size as needed */
    font-weight: 500; /* Make it slightly bolder */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Optional: Add shadow */
  }

  .features-section {
    display: flex;
    justify-content: space-around;
    position: relative;
    max-width: 1400px;
    margin: 50px auto;
    flex-wrap: wrap; /* Allow items to wrap */
  }
  
  .feature-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 30%;
    text-align: center;
    padding: 20px;
    transition: transform 0.2s, opacity 0.5s;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .feature-card.fade-in {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
  }
  
  .feature-card img {
    max-width: 100%;
    border-radius: 8px 8px 0 0; /* Rounded corners on the top */
  }
  
  .feature-card h3 {
    margin: 15px 0 10px;
    font-weight: bold;
    font-size: 1.5em;
    text-align: left;
  }
  
  .feature-card p {
    color: #555;
  }

  .red-background {
    position: relative;
    overflow: hidden; /* Prevent overflow from the pseudo-element */
  }

  .red-background h2 {
    font-size: 3.5rem; /* Adjust size as needed */
    font-weight: bold; /* Make it bold */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Optional: Add shadow for emphasis */
    color: white;
    text-align: center;
    margin-top: 4%;
  }

  .red-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%; /* Occupies 30% of the height of the parent */
    /* background-color: red; */
    background-color: #E21F46;
    
    z-index: -1; /* Places it behind the content */
  }

  .store-front-pic{
    width: 100%;
  }
  
  @media (max-width: 768px) { /* Mobile devices (you can adjust the max-width value as needed) */
    .carousel-caption h2 {
      font-size: 1.9rem; /* Adjust size as needed */
      font-weight: bold; /* Make it bold */
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Optional: Add shadow for emphasis */
      
    }
    .feature-card-p {
      
      font-size:0.1rem;
      text-align: justify;
      /* visibility: collapse; */
    }

    .carousel .carousel-caption {
      top: 6vh; /* Adjust as needed */
      right: 2vh; /* Position from the right */
      width:20vh;
    }

    .features-section {
      flex-direction: column; /* Stack cards vertically */
      align-items: center; /* Center cards */
    }
  
    .feature-card {
      width: 100%; /* Full width on mobile */
      margin-bottom: 20px; /* Space between cards */
    }

    /* .store-front-pic {
      width: 850px;
    } */
    
  }